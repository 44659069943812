<template>
    <div>
        <header-portrate /> 
        <exports-print ref="exportbar" />
    <div>
       <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />

        <v-row class="mainrow m-2" :style="`padding-inline-end:35px;direction:`+lang.dir+`;margin-top:10px !important;`">
            <v-col cols="12" md="12" sm="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin">
                <v-simple-table class="mt-1" :style="`padding-inline:40px;direction: ${lang.dir};`">
                    <thead>
                        <tr>
                            <th colspan="4" class="text-center backBlack">الايرادات</th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack" rowspan="2" style="width:100px;">رقم الحساب</th>
                            <th class="text-center backBlack" rowspan="2" style="">اسم الحساب</th>
                            <th class="text-center backBlack" colspan="2" style="width:300px;">الرصيد</th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack" style="width:150px;">مدين</th>
                            <th class="text-center backBlack" style="width:150px;">دائن</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center backBlack">41</td>
                            <td class="text-center backBlack">ايرادات المبيعات</td>
                            <td class="text-center backBlack">0</td>
                            <td class="text-center backBlack">0</td>
                        </tr>
                        <tr>
                            <td class="text-center">411</td>
                            <td class="text-center">أيرادات المبيعات</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                        </tr>
                        <tr>
                            <td class="text-center">412</td>
                            <td class="text-center">الخصم المكتسب</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                        </tr>
                        <tr>
                            <td class="text-center backBlack">42</td>
                            <td class="text-center backBlack">ايرادات أخرى</td>
                            <td class="text-center backBlack">0</td>
                            <td class="text-center backBlack">0</td>
                        </tr>
                        <tr>
                            <td class="text-center">421</td>
                            <td class="text-center">ايرادات أخرى</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th class="text-center backBlack" colspan="2">مجموع الارصدة</th>
                            <th class="text-center backBlack">0</th>
                            <th class="text-center backBlack">0</th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack" colspan="2">اجمالي الايرادات</th>
                            <th class="text-center backBlack" colspan="2">0</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
            <v-col cols="12" md="12" sm="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin">
                <v-simple-table class="mt-1" :style="`padding-inline:40px;direction: ${lang.dir};`">
                    <thead>
                        <tr>
                            <th colspan="4" class="text-center backBlack">المصروفات</th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack" rowspan="2" style="width:100px;">رقم الحساب</th>
                            <th class="text-center backBlack" rowspan="2" style="">اسم الحساب</th>
                            <th class="text-center backBlack" colspan="2" style="width:300px;">الرصيد</th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack" style="width:150px;">مدين</th>
                            <th class="text-center backBlack" style="width:150px;">دائن</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center backBlack">51</td>
                            <td class="text-center backBlack">مصروف المبيعات</td>
                            <td class="text-center backBlack">0</td>
                            <td class="text-center backBlack">0</td>
                        </tr>
                        <tr>
                            <td class="text-center">511</td>
                            <td class="text-center">مردود المبيعات</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                        </tr>
                        <tr>
                            <td class="text-center">513</td>
                            <td class="text-center">تكلفة المبيعات</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                        </tr>
                        <tr>
                            <td class="text-center">514</td>
                            <td class="text-center">الخصم المسموح به</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                        </tr>
                        <tr>
                            <td class="text-center backBlack">52</td>
                            <td class="text-center backBlack">مصاريف ادارية وعمومية</td>
                            <td class="text-center backBlack">0</td>
                            <td class="text-center backBlack">0</td>
                        </tr>
                        <tr>
                            <td class="text-center">521</td>
                            <td class="text-center">مصاريف ادارية وعمومية</td>
                            <td class="text-center">0</td>
                            <td class="text-center">0</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th class="text-center backBlack" colspan="2">مجموع الارصدة</th>
                            <th class="text-center backBlack">0</th>
                            <th class="text-center backBlack">0</th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack" colspan="2">اجمالي المصروفات</th>
                            <th class="text-center backBlack" colspan="2">0</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
            <v-col cols="12" md="12" sm="12" :style="`direction:`+lang.dir+`;margin-top:3px;text-align:`+lang.align">
                
                <v-simple-table class="mt-1" :style="`padding-inline:40px;direction: ${lang.dir};`">
                    <thead>
                        <tr>
                            <th class="text-center backBlue">اجمالي الارباح / الخسائر</th>
                            <th class="text-center backGreen">0</th>
                        </tr>
                    </thead>
                </v-simple-table>
                <br>
            </v-col>
        </v-row> 
    </div>
        <Footer />
        
 
        
    </div>
</template>

<script>
import axios from 'axios';
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import ExportsPrint from '@/components/exports-print.vue';

export default{
    components:{
        breadCrumbs,TabsComp,
        HeaderPortrate,Footer, ExportsPrint
    },
    data() {
        return {
            
        }
    },
    computed: {
       lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
       
        
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;         
        },
       
    },
    methods: {
        
    },
    created() {
        // this.getStores()
    },
    
}
</script>